import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./cardMain.scss";
import iconPrideTalks from "../../assets/imgs/dashboard/icon_pride_talks.png";
import { Badge } from "reactstrap";
import { audienceService } from "../../services/audience.service";
import Moment from "react-moment";
import dayjs from "dayjs";

export function CardMain() {
  const [listAudienceO, setListAudienceO] = useState([]);
  const itemsPerPage = 1;

  async function getComplete() {
    audienceService.getLastOrDefault(0, 0, itemsPerPage).then((result) => {
      if (result) setListAudienceO(result);
    });
  }

  useEffect(() => {
    getComplete();
  }, []);

  const hoy = dayjs();

  return (
    <>
      {listAudienceO.map((item, index) => {
        //TODO LAGG: cambiar el link pasando como parámetro el Id para abrir el video
        return (
          <Link
            // to=
            // to={`/audience`}
            to={
              hoy > dayjs(item.data.conferenceDate)
                ? `/audience/${item.data.auditoriumId}`
                : "/audience"
            }
            className="itemcard_post_muro pridetalks"
            key={item.data.auditoriumId}
          >
            <h3 className="ttls_in_muro mb-1">
              <span>Videos</span>
            </h3>
            <div className="tag">
              <Badge className="badge t_g">
                {hoy > dayjs(item.data.conferenceDate)
                  ? "Evento disponible"
                  : "Próximamente"}
              </Badge>
            </div>

            <div className="itemcard_content">
              <div className="itemcard_content_picture">
                <img
                  src={item.files && item.files[0]?.path}
                  alt={item.data.title}
                  title={item.data.title}
                />
              </div>

              <div className="itemcard_content_info">
                <h6 className="itemcard_content_title">
                  {item.data.title.length <= 55
                    ? item.data.title
                    : `${item.data.title.substring(0, 55)}...`}
                </h6>
                <p className="itemitemcard_content_description">
                  <Moment
                    date={item.data.conferenceDate}
                    format="dddd DD [de] MMMM [del] YYYY"
                  ></Moment>
                </p>
              </div>
            </div>
          </Link>
        );
      })}
    </>
  );
}
