import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FormPersonal } from "./formPersonal";
import { FormAdditional } from "./formAdditional";
import { FormCheckoutPay } from "./formCheckoutPay";
import { MessageFinish } from "./messageFinish";
import { directoryInfoService } from "../../services/directoryService.service";
import { directoryTypeService } from "../../services/directoryType.service";
import { useParams } from "react-router-dom";
import "./registerServices.scss";
import { Col, Row } from "reactstrap";
import { BsExclamation } from "react-icons/bs";
import { FooterHome } from "../../components/footer/footerHome";
import { Steps } from "antd";
import bg_package from "../../assets/imgs/bg_package.png";
import bg_package_lt from "../../assets/imgs/bg_package_lt.png";
import Swal from "sweetalert2";

export function ItemSelectedPackages(myprops) {
  return (
    <div className="item_selected_packages">
      <h5 className="mb-1">
        {myprops.directoryTypeSelected.directoryTypeName}
      </h5>
      <p className="c_primary">
        {myprops.directoryTypeSelected.shortDescription}
      </p>
      <img
        className="bg_pk_lt"
        src={bg_package_lt}
        alt="Puetes Economía incluyente"
      />
    </div>
  );
}

export function RegisterServices(props) {
  const location = useLocation();
  const dataState = location.state;
  const [activeStep3, setActiveStep3] = useState(false);
  const history = useHistory();
  let { id } = useParams();
  const [pubToEdit, setPublicationToEdit] = useState({});
  const [isEdit, setIdEdit] = useState(false);
  const [loadPicture, setLoadPicture] = useState(false);
  const [file, setFile] = useState([]);
  const [dataCompany, setDataCompany] = useState({});
  const [viewForm, setViewForm] = useState(1);
  const [items, setItems] = useState([]);
  const [directoryType, setDirectoryType] = useState([]);
  const [showButtonPackages, setShowButtonPackages] = useState(true);
  const [viewPackage, setViewPackage] = useState(true);
  const [directoryTypeSelected, setDirectoryTypeSelected] = useState({
    description: "",
    monthlyPrice: 0,
  });
  const [isDoneLoadImg, setIsDoneLoadImg] = useState(false);
  const toggleView = (sec) => {
    if (viewForm === "2") {
      if ((activeStep3 === true && sec !== "1") || sec === "3") {
        subirForm();
      }
    }
    if (viewForm !== sec) setViewForm(sec);
  };

  const [register, setRegister] = useState({
    personal: {},
    additional: {},
    pay: {},
  });

  useEffect(() => {
    if (id !== "" && id !== undefined) {
      setLoadPicture(true);
      directoryInfoService.getById(id).then((data) => {
        if (data == null) {
          Swal.fire({
            text: "Lo sentimos, el registro que está buscando no existe o ha sido dado de baja.",
            icon: "error",
            didClose: () => {
              history.push("/directory");
            },
          });
        } else {
          data.contactImage = Array.isArray(data.contactImage)
            ? data.contactImage
            : data.contactImage.split(",");
          setIdEdit(true);
          setPublicationToEdit(data);
        }
      });
    }
    getDataCompany();
  }, []);

  function getDataCompany() {
    directoryInfoService.getSettingsUser().then((result) => {
      if (result) {
        const object = JSON.parse(result.customizeValues);
        let showDays =
          result.directoryInfoCount <
          parseInt(object.NoPost) + result.directoryInfoExceptionFreePost;
        setDataCompany({
          NoPost: object.NoPost,
          NoDaysGrace: object.NoDaysGrace,
          Amount: object.Amount,
          AmountToVerify: object.AmountToVerify,
          HasTax: result.hasTax,
          DirectoryInfoCount: result.directoryInfoCount,
          IsShow: showDays,
          FreePost: object.FreePost,
        });
      }
    });
  }

  function getDirectoryType() {
    directoryTypeService.getDirectoryType().then((result) => {
      if (result) {
        if (isEdit) {
          setShowButtonPackages(false);
          //aqui se agrega al arreglo de archivos
          let arrayIamge = [];
          if (Array.isArray(pubToEdit.contactImage))
            arrayIamge = pubToEdit.contactImage;
          else arrayIamge.push(pubToEdit.contactImage);

          setUrlImages(arrayIamge);
          setTriggerEffect(true);
          let item = result.find(
            (x) => x.directoryTypeId == pubToEdit.directoryTypeId
          );
          handlViewPackage(item);
        }
        setDirectoryType(result);
      }
    });
  }

  const [urlImages, setUrlImages] = useState([]);
  const [triggerEffect, setTriggerEffect] = useState(false);

  useEffect(() => {
    if (triggerEffect) {
      let promises = urlImages.map((itemimage) => {
        if (itemimage != undefined && itemimage != "") {
          let url = new URL(itemimage);
          let pathname = url.pathname;
          let nombreArchivo = pathname.substring(pathname.lastIndexOf("/") + 1);

          return fetch(itemimage)
            .then((response) => response.blob())
            .then((blob) => {
              // Aquí es donde convertimos el Blob en un File
              let file = new File([blob], nombreArchivo, {
                type: blob.type,
                lastModified: new Date(),
              });
              return file;
            });
        }
      });

      if (promises.length > 0) {
        Promise.all(promises)
          .then((files) => {
            setShowButtonPackages(dataState.isPay ? true : false);

            setIsDoneLoadImg(true);
            setFile(files);
          })
          .catch((error) => console.error("Error:", error));
      } else {
        setShowButtonPackages(dataState.isPay ? true : false);

        setIsDoneLoadImg(true);
      }
    }
  }, [triggerEffect]);

  useEffect(() => {
    if (dataCompany.FreePost != undefined) {
      if (dataCompany.FreePost) {
        setActiveStep3(false);
      } else {
        setActiveStep3(true);
      }

      getDirectoryType();
    }
  }, [dataCompany]);

  function Datepersonal(event, name) {
    const prevRegister = register;
    prevRegister[name] = event;
    setRegister(prevRegister);
  }

  function subirForm() {
    if (register.personal.contacto2 === "" || register.personal.contacto2 === 0)
      register.personal.contacto2 = null;
    let directoryContacts = [
      {
        contactTypeId: 1,
        contactValue: register.personal.contacto1,
        directoryContactId: register.personal?.contacto1DirectoryContactId,
      },
      {
        contactTypeId: 1,
        contactValue: register.personal.contacto2,
        directoryContactId: register.personal?.contacto1optDirectoryContactId,
      },
      {
        contactTypeId: 2,
        contactValue: register.personal.correo,
        directoryContactId: register.personal?.contacto2DirectoryContactId,
      },
    ];

    if (register.additional?.website) {
      directoryContacts.push({
        contactTypeId: 3,
        contactValue: register.additional?.website,
        directoryContactId: register.additional?.websiteId,
      });
    }
    if (register.additional?.linkedin) {
      directoryContacts.push({
        contactTypeId: 4,
        contactValue: register.additional?.linkedin,
        directoryContactId: register.additional?.linkedinId,
      });
    }
    if (register.additional?.facebook) {
      directoryContacts.push({
        contactTypeId: 5,
        contactValue: register.additional?.facebook,
        directoryContactId: register.additional?.facebookId,
      });
    }
    if (register.additional?.twitter) {
      directoryContacts.push({
        contactTypeId: 6,
        contactValue: register.additional?.twitter,
        directoryContactId: register.additional?.twitterId,
      });
    }
    if (register.additional?.instagram) {
      directoryContacts.push({
        contactTypeId: 7,
        contactValue: register.additional?.instagram,
        directoryContactId: register.additional?.instagramId,
      });
    }
    if (register.additional?.tiktok) {
      directoryContacts.push({
        contactTypeId: 8,
        contactValue: register.additional?.tiktok,
        directoryContactId: register.additional?.tiktokId,
      });
    }

    const formData = new FormData();
    formData.append("CompanyId", process.env.REACT_APP_COMPANY);
    formData.append("title", "lic.");
    formData.append("fullName", register.personal.nombre);
    formData.append("directoryDescription", register.personal.descripcion);
    formData.append("location", register.personal.location);
    formData.append("categoryServiceId", register.personal.categoria);
    formData.append("subCategoryServiceId", register.personal.subcategoria);
    formData.append("createUser", localStorage.getItem("userId"));
    formData.append(
      "directoryTypeId",
      directoryTypeSelected.directoryTypeId
        ? directoryTypeSelected.directoryTypeId
        : 0
    );

    file.forEach((element) => {
      formData.append("contactImage", element);
    });

    directoryContacts.forEach((item, index) => {
      formData.append(
        `directoryContacts[${index}].contactTypeId`,
        item.contactTypeId
      );
      formData.append(
        `directoryContacts[${index}].contactValue`,
        item.contactValue
      );
      if (item.directoryContactId) {
        formData.append(
          `directoryContacts[${index}].directoryContactId`,
          item.directoryContactId
        );
      }
    });

    if (isEdit) {
      formData.append("directoryInfoId", pubToEdit.directoryInfoId);
      directoryInfoService.updateDirectory(formData).then((result) => {
        if (activeStep3 && id !== "" && id !== undefined) {
          console.log("dataState", dataState);
          if (
            dataState != undefined &&
            dataState.isPay &&
            id == result.data.directoryInfoId
          )
            setPublicationToEdit(result.data);
          else
            history.push(
              dataState?.origin !== undefined ? dataState.origin : "/directory"
            );
        }
        if (result?.data) setPublicationToEdit(result.data);
      });
    } else
      directoryInfoService.insertDirectory(formData).then((result) => {
        if (result?.data) {
          setIsDoneLoadImg(true);
          setPublicationToEdit(result.data);
        }
        const newResult = register;
        const lstContact = result.data.directoryContacts.filter(
          (element) => element.contactTypeId === 1
        );
        if (lstContact.length > 0)
          newResult.personal.contacto1DirectoryContactId =
            lstContact[0].directoryContactId;
        const lstContact2 = result.data.directoryContacts.filter(
          (element) => element.contactTypeId === 2
        );
        if (lstContact2.length > 0)
          newResult.personal.contacto2DirectoryContactId =
            lstContact2[0].directoryContactId;
        setRegister(newResult);
        setIdEdit(true);
      });
  }

  const steps = [
    {
      title: "Información Personal y Profesional",
    },
    {
      title: "Información adicional",
    },
    {
      title: "Datos de tarjeta",
    },
  ];
  const steps2 = [
    {
      title: "Información Personal y Profesional",
    },
    {
      title: "Información adicional",
    },
  ];

  useEffect(() => {
    if (activeStep3 == true) {
      setItems(
        steps.map((item) => ({
          key: item.title,
          title: item.title,
        }))
      );
    } else {
      setItems(
        steps2.map((item) => ({
          key: item.title,
          title: item.title,
        }))
      );
    }
  }, [activeStep3]);

  const handlViewPackage = (item) => {
    if (item != undefined) {
      let newItem = { ...item }; // Crear una copia del item para evitar la mutación directa
      if (newItem.monthlyPrice > 0) {
        let amount = newItem.monthlyPrice.toLocaleString("es-MX", {
          style: "currency",
          currency: "MXN",
        });
        amount += dataCompany.HasTax === true ? " más IVA" : "";
        newItem.description = newItem.description.replace("@Amount", amount);
      } else {
        newItem.description = newItem.description.replace("@Amount", "gratis");
      }

      setDirectoryTypeSelected(newItem);
      setViewPackage(false);
    }
  };

  const deleteFile = (index) => {
    let currentFiles = [...file];
    // Elimina el archivo en el índice especificado
    currentFiles.splice(index, 1);

    // Actualiza el estado con el nuevo arreglo
    setFile(currentFiles);
  };

  const filterIfIsFreePost = (directoryType) => {
    return dataCompany.FreePost
      ? directoryType.filter((x) => x.monthlyPrice == 0)
      : directoryType;
  };

  return (
    <>
      {(!isEdit && viewPackage) ||
      (dataState?.isPay &&
        isEdit &&
        viewPackage &&
        pubToEdit?.directoryTypeId != undefined) ? (
        <section className="dashboard-container-page wrapper_page_packages_inclusive">
          <div className=" d-flex-between">
            <h2>Economía Incluyente</h2>
            <Link to="/directory" className="btn-back">
              <i className="icon-chevron-left"></i>
              <p>Regresar</p>
            </Link>
          </div>
          <div className="sec_ttl_top text-center">
            <p>Selecciona el paquete de tu preferencia</p>
            <h1>Paquetes Economía incluyente </h1>
            <h3>"Lo quiero, lo tengo" </h3>
          </div>
          <div className="sec_contain_items_packages row">
            {directoryType
              ? filterIfIsFreePost(directoryType).map((itemType) => {
                  return (
                    <article
                      className="box_item_packages col-md-3 col-sm-6"
                      key={`typeId${itemType.directoryTypeId}`}
                    >
                      <div className="item_packages ">
                        <div className="info_items_packages">
                          <h3 className="ttl_name_pack">
                            {itemType.directoryTypeName}
                          </h3>
                          <p className="c_primary">
                            {itemType.shortDescription}
                          </p>
                        </div>
                        <div className="details_items_packages">
                          <ul className="details_ul_items">
                            {itemType.benefits &&
                              JSON.parse(itemType.benefits).map(
                                (beneficio, index) => {
                                  return (
                                    <li
                                      key={`benefit${itemType.directoryTypeId}${index}`}
                                    >
                                      <i className="fa-solid fa-circle-check"></i>{" "}
                                      <span>{beneficio}</span>{" "}
                                    </li>
                                  );
                                }
                              )}
                          </ul>
                        </div>
                        <div className="actions_item_packages">
                          <h1>
                            {itemType.monthlyPrice == 0
                              ? "¡Gratis!"
                              : itemType.monthlyPrice.toLocaleString("es-MX", {
                                  style: "currency",
                                  currency: "MXN",
                                })}
                          </h1>
                          <div className="text-center">
                            <button
                              className="btn--dark trans"
                              onClick={() => handlViewPackage(itemType)}
                            >
                              Obtener Plan
                            </button>
                          </div>
                        </div>
                        <img
                          src={bg_package}
                          alt="Paquetes Economía incluyente"
                        />
                      </div>
                    </article>
                  );
                })
              : ""}
          </div>
          <FooterHome />
        </section>
      ) : !isEdit || (isEdit && isDoneLoadImg) ? (
        <section className="dashboard-container-page">
          <Row>
            <Col lg={12} className="d-flex-between">
              <h2 className="d-flex-start">
                <Link
                  to="/directory"
                  className="btn-back-post register_services"
                >
                  <i className="icon-chevron-left"></i>
                </Link>
                Registro de servicios
              </h2>
              {showButtonPackages ? (
                <button
                  onClick={() => setViewPackage(!viewPackage)}
                  className="btn-back"
                >
                  <i className="icon-chevron-left"></i>
                  <p>Cambiar Paquete</p>
                </button>
              ) : (
                ""
              )}
            </Col>
            {dataCompany.FreePost || directoryTypeSelected.monthlyPrice == 0 ? (
              <Col xl={3} lg={4} md={12} sm={12}>
                <aside className="register-services-aside">
                  <ItemSelectedPackages
                    directoryTypeSelected={directoryTypeSelected}
                  />

                  <div className="icon">
                    <BsExclamation />
                  </div>
                  <div className="paragraph">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: directoryTypeSelected.description + "<br />",
                      }}
                    ></p>
                  </div>
                  <h3 className="text-center c_primary mt-4">¡Disfrútalo!</h3>
                </aside>
              </Col>
            ) : (
              <Col xl={3} lg={4} md={12} sm={12}>
                <aside className="register-services-aside">
                  <ItemSelectedPackages
                    directoryTypeSelected={directoryTypeSelected}
                  />

                  <div className="icon">
                    <BsExclamation />
                  </div>
                  <div className="ticker-wrap mb-3">
                    <div className="ticker">
                      <div className="ticker__item">
                        {dataCompany?.IsShow ? (
                          <h5>
                            ¡Conteniños te da los primeros{" "}
                            {dataCompany.NoDaysGrace} días gratis!
                          </h5>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="paragraph mb-4">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: directoryTypeSelected.description + "<br />",
                      }}
                    ></p>
                  </div>
                  <p>
                    En caso de requerir factura solicitarla a:{" "}
                    <a href="mailto:facturacion@pridemx.com">
                      facturacion@pridemx.com
                    </a>
                  </p>
                </aside>
              </Col>
            )}
            <Col xl={9} lg={8} md={12} sm={12}>
              <section className="register-services-content">
                <article className="register-services-content-steps">
                  {activeStep3 === false ? (
                    <>
                      {
                        {
                          1: <Steps current={0} items={items} />,
                          2: <Steps current={1} items={items} />,
                          3: <Steps current={3} items={items} />,
                        }[viewForm]
                      }
                    </>
                  ) : (
                    <>
                      {
                        {
                          1: <Steps current={0} items={items} />,
                          2: <Steps current={1} items={items} />,
                          3: <Steps current={2} items={items} />,
                          4: <Steps current={3} items={items} />,
                        }[viewForm]
                      }
                    </>
                  )}
                </article>
                <article className="register-services-content-form">
                  {activeStep3 === false ? (
                    <>
                      {
                        {
                          1: (
                            <FormPersonal
                              data={pubToEdit}
                              register={register}
                              viewForm={viewForm}
                              loadPicture={loadPicture}
                              setLoadPicture={setLoadPicture}
                              setFile={setFile}
                              deleteImage={deleteFile}
                              Datepersonal={Datepersonal}
                              toggleView={() => toggleView("2")} //sig
                              activeTab3={false}
                              quantityImage={
                                directoryTypeSelected &&
                                directoryTypeSelected.quantityImage
                                  ? directoryTypeSelected.quantityImage
                                  : 1
                              }
                              showButtonPackages={setShowButtonPackages}
                            />
                          ),
                          2: (
                            <FormAdditional
                              viewForm={viewForm}
                              data={pubToEdit}
                              register={register}
                              Datepersonal={Datepersonal}
                              toBack={() => toggleView("1")}
                              toggleView={() => toggleView("3")}
                              activeTab3={false}
                            />
                          ),
                          3: (
                            <FormCheckoutPay
                              amountToVerify={dataCompany.AmountToVerify}
                              viewForm={viewForm}
                              data={pubToEdit}
                              Datepersonal={Datepersonal}
                              toggleView={() => toggleView("4")}
                              toBack={() => toggleView("2")}
                              showButtonPackages={setShowButtonPackages}
                              directory={{
                                name: register.personal.nombre,
                                type: directoryTypeSelected
                                  ? directoryTypeSelected.shortDescription
                                  : "",
                                amount: directoryTypeSelected
                                  ? directoryTypeSelected.monthlyPrice
                                  : 0,
                                freePost: dataCompany.FreePost,
                              }}
                            />
                          ),
                        }[viewForm]
                      }
                    </>
                  ) : (
                    <div className="form_info_service">
                      {
                        {
                          1: (
                            <FormPersonal
                              data={pubToEdit}
                              register={register}
                              viewForm={viewForm}
                              loadPicture={loadPicture}
                              setLoadPicture={setLoadPicture}
                              setFile={setFile}
                              deleteImage={deleteFile}
                              Datepersonal={Datepersonal}
                              toggleView={() => toggleView("2")} //sig
                              activeTab3={true}
                              quantityImage={
                                directoryTypeSelected &&
                                directoryTypeSelected.quantityImage
                                  ? directoryTypeSelected.quantityImage
                                  : 1
                              }
                              showButtonPackages={setShowButtonPackages}
                            />
                          ),
                          2: (
                            <FormAdditional
                              viewForm={viewForm}
                              data={pubToEdit}
                              register={register}
                              Datepersonal={Datepersonal}
                              toggleView={() => toggleView("3")}
                              toBack={() => toggleView("1")}
                              activeTab3={true}
                            />
                          ),
                          3: (
                            <FormCheckoutPay
                              amountToVerify={dataCompany.AmountToVerify}
                              viewForm={viewForm}
                              data={pubToEdit}
                              Datepersonal={Datepersonal}
                              toggleView={() => toggleView("4")}
                              toBack={() => toggleView("2")}
                              showButtonPackages={setShowButtonPackages}
                              directory={{
                                name: register.personal.nombre,
                                type: directoryTypeSelected
                                  ? directoryTypeSelected.shortDescription
                                  : "",
                                amount: directoryTypeSelected
                                  ? directoryTypeSelected.monthlyPrice
                                  : 0,
                                freePost: dataCompany.FreePost,
                              }}
                            />
                          ),
                          4: <MessageFinish />,
                        }[viewForm]
                      }
                    </div>
                  )}
                </article>
              </section>
            </Col>
          </Row>
          <FooterHome />
        </section>
      ) : (
        ""
      )}
    </>
  );
}
