import React, { useEffect, useState} from "react";
import { useParams } from 'react-router-dom';

import "moment/locale/es";
import { BoxListCourse } from "./boxListCourse";
import "./courseView.scss";
import { courseViewService } from "../../services/courseView.service";
import { useSelector } from "react-redux";
import { AdvertisingSection } from "../../components/advertisingSection/advertisingSection";
import { FooterHome } from "../../components/footer/footerHome";
import { useCompanyHoliday } from "../../services/companyHoliday.service";
import imgHeader from "../../assets/imgs/palomitas.png";
import {Tabs} from "antd";
import {BoxTickets} from "./boxTickets";

export const CourseView = () => {
  const [dataGrid, setDataGrid] = useState([]);
  const calendarStrings = useSelector((state) => state.calendarStrings);
  const [activeAsideSurvey, setStateAsideSurvey] = useState("");
  const [userProfile, setUserProfile] = useState({});
  const [dataGridCompleted, setDataGridCompleted] = useState([]);
  const [sliderItems, setSliderItems] = useState([]);
  const [banner, setBanner] = useState([]);
  const [defaultBanner, setDefaultBanner] = useState(true);
  const [holyday, setHolyday] = useState({ type: 0, src: "" });
  const [active, setActive] = useState("sec_a");

  const params = useParams();

  const AsidePurchaseBox = () => setStateAsideSurvey(activeAsideSurvey === "" ? " is-show" : "");

  const GetUserProfileCourse = async () => {
    courseViewService.GetUserProfileCourse().then((result) => {
      if (result) {
        setUserProfile(result[0]);
      }
    });
  }

  const getCourse = async () => {
    courseViewService.getCourse().then((result) => {
      if (result) {
        setDataGrid(result);
      }
    });
  }

  const getCourseCompleted = async () => {
    courseViewService.getCourseComplete().then((result) => {
      if (result) {
        setDataGridCompleted(result);
      }
    });
  }

  const getCompanyHolidays = async () => {
    if (8) {
      useCompanyHoliday.getCompanyHolidayFront(8).then((result) => {
        if (result) {
          const temp = [];
          result.map((ch) => {
            if (holyday.type !== 6) {
              temp.push({
                key: ch.companyHolidayId.toString(),
                file: ch.imagePath,
              });
              setDefaultBanner(false);
            }
          });
          setSliderItems(temp);
          const bannerItem = temp
            .sort(() => Math.random() - Math.random())
            .find(() => true);
          setBanner(bannerItem);
        }
      });
    }
  }

  const setCompleteCourse = (course) => {
    if (!course.isCompleted) {
      courseViewService.completeCourse(course.courseId).then((result) => {
        getCourse();
        getCourseCompleted();
        GetUserProfileCourse();
      });
    }
  }

  const onChange = (key) => setActive(key)

  const items = [
    {
      key: 'sec_a',
      label: <>
        <h6>CARTELERA</h6>
        <p>en Conteniños</p>
      </>,
      children: <>
        <div className="item_box_navbar">
          <BoxTickets />
        </div>
      </>,
    },
    {
      key: 'sec_b',
      label: <>
        <h6>RECOMENDACIONES</h6>
        <p>Eventos generales</p>
      </>,
      children: <>
        <BoxListCourse
            dataGrid={dataGrid}
            setCompleteCourse={setCompleteCourse}
            propCourseId = {params?.courseId}
        />
      </>,
    },
  ];

  useEffect(() => {
    getCourse();
    GetUserProfileCourse();
    getCourseCompleted();
    getCompanyHolidays();
    if(params?.courseId) setActive('sec_b')
    // courseId ? setActive('sec_a') : setActive('sec_b')
  }, []);

  return (
    <section className="course-container-view">
      <div className="course-view-banners">
        <div className="course-view-banners_pt1">
          <h2 className="course-view-title">Cartelera</h2>
          <h2 className="ttl_principal_mod">¿Tienes algún plan para hoy? </h2>
          <h4>¡Aquí te recomendamos uno!</h4>
        </div>
        <div className="course-view-banners_pt2">
          {banner ? (
            <AdvertisingSection HolType={8} />
          ) : (
            <img src={imgHeader} className="header_imgs" alt={''} />
          )}
        </div>
      </div>

      {dataGrid.length === 0 ? (
        <div className="empty-grid">
          <p>No hay publicaciones nuevas</p>
          <i className="icon-empty"></i>
        </div>
      ) : (
        <div className="course-view-navbar">
          <div className="content_box_navbar">
            <Tabs
                className={'box_navbar'}
                defaultActiveKey={active}
                items={items}
                onChange={onChange}
            />
          </div>
        </div>
      )}

      <FooterHome />
    </section>
  );
}
