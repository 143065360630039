import React, { useRef, useState } from "react";
import { EditorPost } from "./editor";
import SearchCompanyPost from "./searchCompanyPost";
import { companyPostService } from "../../services/companyPost.service";
import { SelectUsers } from "../../components/Helpers/selectUsers";
import { AudienceUsers } from "../../components/Helpers/audienceUsers";
import { CompanyPostAttachment } from "../../components/companyPost/companyPostAttachment";
import { AdminGallery } from "./galleries/adminGallery";
import {FaSave, FaTimesCircle} from "react-icons/fa";
import {Button, Col, Form, Input, Row, Tabs} from "antd";
import {TitleAdmin} from "../components/titleAdmin/titleAdmin";
import {useHistory} from "react-router-dom";
import {PlusCircleOutlined} from "@ant-design/icons";
import {HeadingAdmin} from "../components/headingAdmin/headingAdmin";
import Swal from "sweetalert2";
import {Checkbox} from "../components/checkbox/checkbox";
import {InputFile} from "../components/inputFile/inputFile";

import "./ourCompanyAdmin.scss";
import MySwal from "sweetalert2";
import {storageService} from "../../services/storage.service";

export const CompanyPost = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [companyInit, setCompanyInit] = useState({
    CompanyPostId: 0,
    CompanyPostCategoryId: 1,
    Title: "",
    Subtitle: "",
    Text: "",
    DepartmentId: 0,
    JobRoleId: 0,
  });
  const [localfiles, setLocalFiles] = useState([]);
  const [localAttachments, setLocalAttachments] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [userSelec, setUserSelec] = useState([]);
  const gridRef = useRef();
  const history = useHistory();
  const [thumbUpdated, setThumbUpdated] = useState(false);
  const [PDFUpdated, setPDFUpdated] = useState(false);

  const onSave = () => {
    companyInit.ListUser = userSelec;

    if(localfiles.length === 0) return MySwal.fire({
      title: "No hay una imagen para la publicación",
      icon: "error",
      confirmButtonText: "Aceptar"
    });

    if (userSelec.length > 0) {
      if (companyInit.CompanyPostId === 0) {
        companyPostService
          .create(companyInit, localfiles, localAttachments)
          .then((result) => {
            gridRef.current.refreshDataGrid();
            MySwal.fire({
              title: "¡Se creó correctamente!",
              icon: "success",
              confirmButtonText: "Confirmar"
            });
          });
      } else {
        companyPostService
          .update(companyInit, !(thumbUpdated) ? [] : localfiles, !(PDFUpdated) ? [] : localAttachments)
          .then((result) => {
            setThumbUpdated(false)
            setPDFUpdated(false);
            gridRef.current.refreshDataGrid();
          });
      }
      setUserSelec([]);
      clearCompanyInit();
      setShowEdit(false);
      setLocalFiles([]);
      setLocalAttachments([]);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Ups...',
        text: 'Debes elegir al menos un usuario',
      })
    }
  };

  const handleDeleteImage = (files,file,typeFile) => {
    if((companyInit.CompanyPostId !== 0)) {
      if(typeFile === 'image') {
        storageService
            .deleteStorage({
              EntityId: file.entityId,
              EntityTypeId: file.entityTypeId,
              Sequence: file.sequence,
            })
            .then((result) => setLocalFiles([]));
      }

      if(typeFile === 'pdf'){
        storageService
            .deleteStorage({
              EntityId: file.entityId,
              EntityTypeId : file.entityTypeId,
              Sequence: file.sequence,
            })
            .then((result) => setLocalAttachments([]));
      }
    } else {
      if(typeFile === 'image') setLocalFiles(file);
      if(typeFile === 'pdf') setLocalAttachments(file);
    }
  };

  const handleOnCancel = () => {
    clearCompanyInit();
    setShowEdit(false);
    setUserSelec([]);
    setLocalFiles([]);
    setLocalAttachments([]);
  };

  const clearCompanyInit = () => {
    setCompanyInit({
      CompanyPostId: 0,
      CompanyPostCategoryId: 1,
      Title: "",
      Subtitle: "",
      Text: "",
      DepartmentId: 0,
      JobRoleId: 0,
    })
  }

  const handleOnNew = () => {
    setCompanyInit(companyInit);
    setShowEdit(true);
    setUserSelec([]);
    setLocalFiles([]);
    setLocalAttachments([]);
  };

  const handleRowSelect = (gridItem) => {
    console.log(gridItem,'fdasdf')
    setShowEdit(true);
    setCompanyInit({
      CompanyPostId: gridItem.companyPostId,
      Title: gridItem.title,
      Subtitle: gridItem.subtitle,
      Text: gridItem.text,
      CompanyPostCategoryId: gridItem.companyPostCategoryId,
    });

    companyPostService.getCompanyPostImagesById(gridItem.companyPostId).then((result) => {
        if (result.files) setLocalFiles(result.files);
    });

    companyPostService.getCompanyPostFilesById(gridItem.companyPostId).then((result) => {
        if (result.files[0]) setLocalAttachments(result.files);
    });
  };

  const handleCheckboxChange = (value) => {
    const valueInt = (value) ? 2 : 1
    setCompanyInit({...companyInit, CompanyPostCategoryId: valueInt})
  }

  const handleInputChange = (e) => setCompanyInit({...companyInit, [e.target.name]: e.target.value});

  const onChangeTab = (key) => setActiveTab(key);

  const handleLocalFiles = (files) => {
    if(companyInit.CompanyPostId) setThumbUpdated(true);
    setLocalFiles(files);
  }

  const handleLocalAttachment = (files) => {
    if(companyInit.CompanyPostId)  setPDFUpdated(true);
    setLocalAttachments(files);
  }

  const items = [
    {
      key: '1',
      label: 'Artículos',
      children: <>
          <HeadingAdmin
              text={!(showEdit) ? 'Agregar entrada' : 'Cancelar'}
              icon={(!showEdit) ? <PlusCircleOutlined /> : <FaTimesCircle />}
              event={() => !(showEdit) ? handleOnNew() : handleOnCancel()}
          />
          {!(showEdit )? (
              <SearchCompanyPost handleRowSelect={handleRowSelect} ref={gridRef} />
          ) :
              <Form
                  onFinish={onSave}
                  className={'form-backoffice'}
                  layout={'vertical'}
                  fields={[
                    {
                      'name': ['JobRoleId'],
                      'value': companyInit.JobRoleId
                    },
                  ]}
              >
                <Row gutter={16}>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                        label={'Título de entrada'}
                        htmlFor={'Title'}
                        name={'Title'}
                        rules={[
                          {
                            required: true,
                            message: '*Campo Requerido'
                          },
                          {
                            pattern: new RegExp(/^\S/),
                            message: "No se aceptan espacios en blanco al inicio",
                          },
                        ]}
                        initialValue={companyInit.Title}
                    >
                      <Input
                          name="Title"
                          onChange={handleInputChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                        label={'¿Es historia de Aventura?'}
                    >
                      <Checkbox
                          text={''}
                          name={(companyInit.CompanyPostCategoryId === 2)}
                          handleValueCheck={handleCheckboxChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                        label={'Imagen de artículo'}
                    >
                      <InputFile
                          onFilesSelected={handleLocalFiles}
                          edit={(companyInit.CompanyPostId !== 0)}
                          valueFile={localfiles}
                          width={800}
                          height={800}
                          nameFile={'localfiles'}
                          accept={['image']}
                          deleteImage={(files,file) => handleDeleteImage(files,file,'image')}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                        label={'Archivo adjunto'}
                    >
                      <InputFile
                          onFilesSelected={handleLocalAttachment}
                          edit={(companyInit.CompanyPostId !== 0)}
                          valueFile={localAttachments}
                          nameFile={'localattachments'}
                          accept={['image','pdf']}
                          width={1200}
                          height={800}
                          deleteImage={(files,file) => handleDeleteImage(files,file,'pdf')}
                      />
                    </Form.Item>
                  </Col>
                  {
                    (companyInit.CompanyPostId !== null && companyInit.CompanyPostId > 0) ? (
                      <Col md={16} sm={24} xs={24}>
                          <CompanyPostAttachment
                              CompanyPostId={companyInit.CompanyPostId}
                              IsDelete={true}
                          />
                      </Col>
                    ) : null
                  }
                  <Col xs={24}>
                    <EditorPost
                        content={companyInit.Text}
                        name="Text"
                        handleInputChange={handleInputChange}
                    />
                  </Col>
                  <Col xs={24}>
                    {
                      (companyInit.CompanyPostId > 0) ?
                          <AudienceUsers targetId={companyInit.CompanyPostId} eventType="Articulo" selectUser={setUserSelec} />
                          :
                          <SelectUsers selectUser={setUserSelec} title={"Entrada dirigida a:"} />
                    }
                  </Col>
                  <Col xs={24}>
                    <div className="form-backoffice-footer">
                      <Button htmlType={'submit'} type="primary">
                        <p>Guardar</p>
                        <FaSave />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
          }
      </>,
    },
    {
      key: '2',
      label: 'Galerías',
      children: <AdminGallery />,
    }
  ];

  return (
    <section className="dashboard-admin-container">
      <TitleAdmin
          title={'El Multiverso'}
          subtitle={'Gestión de artículos y galería'}
          event={() => history.push("/admin")}
      />
      <Tabs
          defaultActiveKey={activeTab}
          items={items}
          onChange={onChangeTab}
      />
    </section>
  );
}
