import React from "react";
import { HubItemProvider } from "../../components/hubItemProvider/hubItemProvider";
import { ToolDiscount } from "../../components/toolDiscount/toolDiscount";
import "./discount.scss";
import { Col, Row } from "reactstrap";
import bannerBeneficios from "../../assets/imgs/banner-beneficios.png";

import { FooterHome } from "../../components/footer/footerHome";
export function Discount() {
  return (
    <section className="dashboard-container-page">
      <Row>
        <Col sm={12}>
          <h2>Beneficios</h2>
        </Col>
        <Col sm={12}>
          <div className="drawer__bg__sections">
            <img
              src={bannerBeneficios}
              alt="Ser parte de la comunidad PrideMx te da estos beneficios"
            />
          </div>
          {/* <div className="discount-header">
                        <div className="discount-header-info">
                            <h4 className="ttls fw600">Ser parte de la comunidad PrideMx te da estos beneficios</h4>
                            <p>Encuentra tus marcas favoritas y sorpréndete </p>
                        </div>
                        <div className="discount-header-thumbnail">
                            <img src={img} alt="" />
                        </div>
                    </div> */}
        </Col>
        <Col sm={12}>
          <ToolDiscount />
        </Col>
        <Col sm={12}>
          <div className="discount-cards">
            <HubItemProvider />
          </div>
        </Col>
        <Col sm={12}>
          <FooterHome />
        </Col>
      </Row>
    </section>
  );
}
