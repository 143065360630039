import React, { useEffect, useState } from "react";
import { Badge } from "reactstrap";
import { Link } from "react-router-dom";

import "./cardPrimaryHome.scss";
import imgEmpty from "../../assets/imgs/no-image.jpeg";
import { courseViewService } from "../../services/courseView.service";

export function CardPrimaryHome() {
  const [dataGrid, setDataGrid] = useState([]);

  async function getCourse() {
    courseViewService.getLatestCourse().then((result) => {
      if (result) setDataGrid(JSON.parse(result));
    });
  }
  useEffect(() => {
    getCourse();
  }, []);

  return (
    <>
      {dataGrid.slice(0, 1).map((item) => {
        return (
          <Link
            to={`/course-view/course/${item.CourseId}`}
            className="itemcard_post_muro cartelera"
            key={item.CourseId}
            // params={{ courseId: item.CourseId }}
          >
            <h3 className="ttls_in_muro mb-1">
              <span>
                Cartelera 
              </span>
            </h3>
            <div className="tag">
              <Badge  className="badge">
                Evento reciente
              </Badge>
            </div>

            <div className="itemcard_content">
              <div className="itemcard_content_picture">
              {item.Path ? (
                <img src={item.Path} alt={item.Name} title={item.Name} />
              ) : (
                <img src={imgEmpty} alt={item.Name} title={item.Name} />
              )}
              </div>

              <div className="itemcard_content_info">
                <h6 className="itemcard_content_title">{item.Name}</h6>
                {item.Description ? (
                  <p className="itemitemcard_content_description">
                    {item.Description.length <= 60
                      ? item.Description
                      : `${item.Description.substring(0, 60)}...`}
                  </p>
                ) : (
                  <p>No hay descripción disponible</p>
                )}
              </div>
            </div>
          </Link>
        );
      })}
    </>
  );
}
