import React, { useState, useEffect } from "react";
import "moment/locale/es";
import { SliderHome } from "../../components/sliderHome/sliderHome";
import { BoxStories } from "../../components/boxStories/boxStories";
import { BoxSchedule } from "../../components/boxSchedule/boxSchedule";
import { companyPostService } from "../../services/companyPost.service";
import { courseViewService } from "../../services/courseView.service";
import { backendService } from "../../services/backend.service";
import { CarouselMainHome } from "../../components/carouselMainHome/carouselMainHome";
import { CardUser } from "../../components/cardUser/cardUser";
import { CardPrimaryHome } from "../../components/cardPrimaryHome/cardPrimaryHome";
import { CardSecondaryHome } from "../../components/cardSecondaryHome/cardSecondaryHome";
import { CardMain } from "../../components/cardMain/cardMain";
import { CarouselAsideHome } from "../../components/carouselAsideHome/carouselAsideHome";
import { FooterHome } from "../../components/footer/footerHome";
import "@splidejs/react-splide/dist/css/splide-core.min.css";
import "./home.scss";

export function Home() {
  const [lastPost, setLastPost] = useState({});
  const [directoryItems, setDirectoryItems] = useState([]);
  const [auditoriumItems, setAuditoriumItems] = useState([]);
  const [ourWorld, setOurWorld] = useState([]);
  const [lastCourse, setLastCourse] = useState([]);
  const [myModal, setMyModal] = useState("inShow");

  useEffect(() => {
    backendService.widgets().then((data) => {
      if (data && data.auditoriumWidget && data.auditoriumWidget.length > 0) {
        let auditoriumItems = data.auditoriumWidget.map(({ data }) => data);
        let listSorted = auditoriumItems.sort(function (a, b) {
          return (
            Math.abs(Date.now() - new Date(a.conferenceDate)) -
            Math.abs(Date.now() - new Date(b.conferenceDate))
          );
        });

        setAuditoriumItems(listSorted);
      }
      if (data.surveyWidget != null) {
        let date = new Date();
        date.setDate(date.getDate() + data.surveyWidget.expirationDays);
        data.surveyWidget.expirationDate = date;
      }

      if (data.directoryInfoWidget?.length > 0)
        setDirectoryItems(data.directoryInfoWidget);
    });
    getLatestPost();
    getOrWorld();
    getLatestCourse();
    setTimeout(() => setDoodle(), 8000);
  }, []);

  async function getLatestPost() {
    companyPostService.getLatest(2).then((result) => {
      if (result && result.length > 0) setLastPost(result[0]);
    });
  }

  async function getOrWorld() {
    companyPostService.getLatest(1).then((result) => {
      setOurWorld(result);
    });
  }

  async function getLatestCourse() {
    courseViewService.getLatestCourse().then((result) => {
      let listCourse = JSON.parse(result);
      setLastCourse(listCourse);
    });
  }

  async function setDoodle() {
    setMyModal(myModal === "");
  }

  function getWidthDimensions() {
    const { innerWidth: width } = window;

    return width;
  }

  const [overlay, setOverlay] = useState(false);
  const toggleOverlay = () => setOverlay(!overlay);

  const [windowDimensions, setWindowDimensions] = useState(
    getWidthDimensions()
  );

  const handleOverlay = (width) => {
    if (width >= 1280) setOverlay(false);
  };

  useEffect(() => {
    const handleResize = () => setWindowDimensions(getWidthDimensions());
    handleOverlay(getWidthDimensions());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleOverlay]);

  return (
    <>
      <section className={`dashboard-side-top`}>
        <SliderHome />
      </section>
      <div className={'page-home'}>
        <section className="dashboard-side-left">
          <CardUser/>
          <FooterHome inMuro/>
        </section>
        <section className={`dashboard-side-center`}>
          <article className="center_content">
            {/* <SliderHome /> */}
            <div className="center_content_pts">
              <CardPrimaryHome/>
              <CardSecondaryHome/>
            </div>

            <CarouselMainHome ourWorld={ourWorld}/>

            <div className="center_content_pts">
              <CardMain/>
              <BoxStories
                  tituloPost={lastPost && lastPost.title}
                  urlImg={lastPost && lastPost.urlImage}
                  description={lastPost && lastPost.description}
                  linkURL={`/stories-pride/2/${lastPost.companyPostId}`}
              />
            </div>
          </article>
        </section>
        <section className={`dashboard-side-right`}>
          <CarouselAsideHome/>
          {/* <DashboardBanner /> */}
          <FooterHome/>
        </section>
      </div>
    </>
  );
}
