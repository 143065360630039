import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./register.scss";
import { useSelector } from "react-redux";
import "dayjs/locale/es";
import { Button, Col, DatePicker, Flex, Form, Input, Row, Select } from "antd";
import { IoCheckmarkCircle } from "react-icons/io5";
import dayjs from "dayjs";
import { userService } from "../../services/user.service";
import { RegisterAside } from "./registerAside";
import { RegisterHeader } from "./registerHeader";
import { FooterFull } from "../../components/footer/footerFull";

export const Register = () => {
  const history = useHistory();
  const accountSettings = useSelector((state) => state.accountSettings);
  const { referralToken } = useParams();
  const [user, setUser] = useState({
    name: "",
    lastname: "",
    birthday: "",
    email: "",
    password: "",
    pronoun: 0,
  });
  const [validatePassword, setValidatePassword] = useState(false);
  const oneCharacter = useRef();
  const oneN = useRef();
  const oneLetterL = useRef();
  const oneLetterU = useRef();
  const min8Characters = useRef();
  const [form] = Form.useForm();
  const [currentDate, setCurrentDate] = useState(dayjs().subtract(18, "years"));

  const onSubmit = () => {
    if (accountSettings.activateRegistration) registerUser(user);
  };

  const registerUser = (data) => {
    data.CompanyId = process.env.REACT_APP_COMPANY;
    data.CountryStateTypeId =
      data.CountryStateTypeId === "" ? "1" : data.CountryStateTypeId;
    data.MaritalStatusTypeId =
      data.MaritalStatusTypeId === "" ? "1" : data.MaritalStatusTypeId;
    data.NationalityTypeId =
      data.NationalityTypeId === "" ? "1" : data.NationalityTypeId;
    data.PostalCode = "";
    data.RFC = data.iniciales + data.fechanac;
    data.ReferralToken = referralToken;
    data.gender = "F";
    data.MiddleName = "";
    data.mobileConfirm = "";
    data.mobile = "";
    userService.register(data);
    form.resetFields();
  };

  const handleInputValue = (e) =>
    setUser({ ...user, [e.target.name]: e.target.value });

  const handleSelectPronoun = (value) => setUser({ ...user, pronoun: value });

  const handlePassword = (e) => {
    const oneLetterUppercase = /.*[A-Z].*/;
    const oneLetterLowercase = /.*[a-z].*/;
    const oneNumber = /.*[0-9].*/;
    const oneSpecialCharacter =
      /(.*[!@#$%\^&*(){}[\]<>?/|-]+|.*[!@#$%\^&*(){}[\]<>?/|\-]+)/;

    const noSpace = e.target.value.replace(/ /g, "");

    if (String(noSpace).length >= 8)
      min8Characters.current.className = "success";
    else min8Characters.current.className = "";

    if (oneLetterUppercase.test(noSpace))
      oneLetterU.current.className = "success";
    else oneLetterU.current.className = "";

    if (oneLetterLowercase.test(noSpace))
      oneLetterL.current.className = "success";
    else oneLetterL.current.className = "";

    if (oneNumber.test(noSpace)) oneN.current.className = "success";
    else oneN.current.className = "";

    if (oneSpecialCharacter.test(noSpace))
      oneCharacter.current.className = "success";
    else oneCharacter.current.className = "";

    form.setFieldValue("password", noSpace);
    setUser({ ...user, [e.target.name]: noSpace });
  };

  const handleBirthday = (_str, _date) => setUser({ ...user, birthday: _date });

  const handleDisabledDate = (current) => {
    return dayjs().subtract(18, "years") < current;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="register__container">
      <article className="register__form">
        <Form
          form={form}
          className={"-form-light"}
          onFinish={onSubmit}
          layout={"vertical"}
        >
          <RegisterHeader title={"Quiero"} subtitle={"registrarme"} />
          <Row gutter={16}>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label={"Tu nombre"}
                name={"firstName"}
                htmlFor={"firstName"}
                rules={[
                  {
                    required: true,
                    message: "*Campo requerido",
                  },
                ]}
                onChange={handleInputValue}
              >
                <Input name={"firstName"} size={"large"} />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label={"Apellido"}
                name={"lastName"}
                htmlFor={"lastName"}
                rules={[
                  {
                    required: true,
                    message: "*Campo requerido",
                  },
                ]}
                onChange={handleInputValue}
              >
                <Input name={"lastName"} size={"large"} />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label={"Tu cumpleaños"}
                name={"birthday"}
                htmlFor={"birthday"}
                rules={[
                  {
                    required: true,
                    message: "*Campo requerido",
                  },
                ]}
              >
                <DatePicker
                  name={"birthday"}
                  size={"large"}
                  placeholder={"Cumpleaños"}
                  picker={"date"}
                  onChange={handleBirthday}
                  showToday={false}
                  onFocus={() => {
                    form.setFieldValue("birthday", currentDate);
                    setUser({ ...user, birthday: currentDate });
                  }}
                  disabledDate={handleDisabledDate}
                  format={"DD/MMM/YYYY"}
                />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label={"Género"}
                name={"genre"}
                htmlFor={"genre"}
                rules={[
                  {
                    required: true,
                    message: "*Campo requerido",
                  },
                ]}
              >
                <Select name={"genre"} onChange={handleSelectPronoun}>
                  <Select.Option value={1}>Masculino</Select.Option>
                  <Select.Option value={2}>Femenino</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label={"Tu correo"}
                name={"email"}
                htmlFor={"email"}
                rules={[
                  {
                    required: true,
                    message: "*Campo requerido",
                  },
                  {
                    type: "email",
                    message: "No es un formato de correo válido",
                  },
                ]}
                onChange={handleInputValue}
              >
                <Input name={"email"} size={"large"} />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label={"Contraseña"}
                htmlFor={"password"}
                name={"password"}
                rules={[
                  {
                    required: true,
                    message: "*Campo requerido",
                  },
                  {
                    min: 8,
                    message: "",
                  },
                  {
                    pattern: new RegExp(/.*[A-Z].*/),
                    message: "",
                  },
                  {
                    pattern: new RegExp(/.*[a-z].*/),
                    message: "",
                  },
                  {
                    pattern: new RegExp(/.*[0-9].*/),
                    message: "",
                  },
                  {
                    pattern: new RegExp(
                      /(.*[!@#$%\^&*(){}[\]<>?/|-]+|.*[!@#$%\^&*(){}[\]<>?/|\-]+)/
                    ),
                    message: "",
                  },
                ]}
                onChange={handlePassword}
              >
                <Input.Password
                  name={"password"}
                  size={"large"}
                  onBlur={() => setValidatePassword(false)}
                  onFocus={() => setValidatePassword(true)}
                />
              </Form.Item>
              <div
                className={`msg-password`}
              >
                <ul className={"msg-password__list"}>
                  <li>
                    <span ref={min8Characters}>
                      <IoCheckmarkCircle />
                    </span>
                    Mínimo 8 carácteres
                  </li>
                  <li>
                    <span ref={oneLetterU}>
                      <IoCheckmarkCircle />
                    </span>
                    1 letra mayúscula
                  </li>
                  <li>
                    <span ref={oneLetterL}>
                      <IoCheckmarkCircle />
                    </span>
                    1 letra minúscula
                  </li>
                  <li>
                    <span ref={oneN}>
                      <IoCheckmarkCircle />
                    </span>
                    1 número
                  </li>
                  <li>
                    <span ref={oneCharacter}>
                      <IoCheckmarkCircle />
                    </span>
                    1 carácter especial
                  </li>
                </ul>
              </div>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label={"Confirma tu contraseña"}
                name={"confirmPassword"}
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("¡Las contraseñas no coinciden!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password name={"confirmPassword"} size={"large"} />
              </Form.Item>
            </Col>
          </Row>
          <div className={"-form-light__footer"}>
            <Button
              htmlType={"button"}
              className={"outline-dark"}
              onClick={() => history.push("/")}
            >
              Iniciar sesión
            </Button>
            <Button htmlType={"submit"} type={"primary"}>
              Registrarme
            </Button>
          </div>
        </Form>
        <RegisterAside />
      </article>
      <FooterFull />
    </section>
  );
};
