import { callApi } from './service'

export const genderIdentityService = {
    getAll,
    
};

async function getAll(companyId) {
    return callApi(`/BackEnd/GetGenderIdentityCatalog`, 'GET').then((result) => {
        if (result.responseCode === '00') {
            return result.data;
        } else {
            alert(result.responseMessage);
            return null;
        }

    });
}


