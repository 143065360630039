import React, { useState } from "react";

import "./interestGroup.scss";
import bannerGroups from "../../assets/imgs/banner-grupos.png";
import { Col, Row } from "antd";
import { FooterHome } from "../../components/footer/footerHome";
import { useGenericSection } from "../../services/genericSection.service";
import { useEffect } from "react";
import InterestGroupCard from "../../components/GenericSection/interestGroupCard";
import InfiniteScroll from "react-infinite-scroll-component";

export function InterestGroup() {
  const [data, setData] = useState([]);
  const [disabledHasMore, setDisabledHasMore] = useState(false);

  const loadMore = () => {
    if (data.length > 0) {
      const lastitem = data.slice(-1).pop();
      loadData(lastitem.genericSectionItemId);
    }
  };

  const loadData = (id) => {
    useGenericSection.getGenericItemByCompanyAndSection(10, id, 9).then(items => {
      if (items.length > 0) {
        const newItems = [...data, ...items];
        setData(newItems);
      } else {
        setDisabledHasMore(true);
      }
    });
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <section className="dashboard-container-page">
      <h2>Grupos de Interés</h2>
      <div className="drawer__bg__sections">
        <img
          src={bannerGroups}
          alt="Grupos donde nuestra comunidad es protagonista"
        />
      </div>
      {/* <div className="interest-group-header">
        <div className="title">
          <h3>Grupos donde nuestra comunidad es protagonista</h3>
        </div>
        <div className="imgs">
          <img src={imgHeader} alt="" />
        </div>
      </div> */}

      <InfiniteScroll
        dataLength={data.length}
        next={() => loadMore()}
        hasMore={!disabledHasMore}
        loader={<h4>No tienes Grupos de Interés previos</h4>}
        scrollableTarget="scrollableDiv"
        style={{overflow: 'unset !important'}}
      >
          <Row>
              {data.map((item, index) => {
                  return (
                      <Col xl={6} lg={8} md={12} sm={24} xs={24}>
                        <InterestGroupCard key={index} item={item} />
                      </Col>
                  )
              })}
          </Row>
      </InfiniteScroll>

      <Row>
        <Col xs={24}>
          <FooterHome />
        </Col>
      </Row>
    </section>
  );
}
