import React from 'react'
import Logo from "../../assets/imgs/Logo.png";
import { ModalContact } from "../../components/modalContact/modalContact";

export const FooterFull = () => {

  const AnioActual = () => {
    var fecha = new Date().getFullYear();
    return <>{fecha}</>;
  };

  return (
    <footer className="wrapper_footer ">
    <div className="container-lg">
      <div className="sec_footer">
        <div className="cont_brand">
          <a href="#welcome">
            <img
              className="logo_brand"
              src={Logo}
              alt="Conteninos"
            />
          </a>
          <div className="box_social_net">
            <div className="slabel">Síguenos en</div>
            <div className="box_link_fa">
              <a
                target="_blank"
                className="facebook"
                href="https://www.facebook.com/conteninos/"
              >
                <i className="fa fa-facebook-f"></i>
              </a>
              <a
                target="_blank"
                className="instagram"
                href="https://instagram.com/conteninos"
              >
                <i className="fa fa-instagram"></i>
              </a>
              <a
                target="_blank"
                className="youtube"
                href="https://www.youtube.com/@conteninos1226"
              >
                <i className="fab fa-youtube"></i>
              </a>
              <a
                target="_blank"
                className="tiktok"
                href="https://www.tiktok.com/@conteninos"
              >
                <i className="fab fa-tiktok"></i>
              </a>
            </div>
          </div>
        </div>
        <ul className="ul_links">
          <li>
            <h6>Soporte</h6>
          </li>
          <li>
            <ModalContact />
          </li>
          {/* <li>
            <a
              className="link_footer"
              target="_blank"
              href="/ambienteseguro.pdf"
            >
              Decálogo
            </a>
          </li> */}
          <li>
            <a
              className="link_footer"
              target="_blank"
              href="/avisoprivacidad.pdf"
            >
              Aviso de Privacidad
            </a>
          </li>
          <li>
            <a
              className="link_footer"
              target="_blank"
              href="/terminosycondiciones.pdf"
            >
              Términos y Condiciones
            </a>
          </li>
        </ul>
      </div>
      <div className="foot_right">
        <AnioActual /> ©Conteniños. Todos los derechos reservados
      </div>
    </div>
  </footer>
  )
}
