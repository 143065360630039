import React, { useEffect, useState } from "react";
import { backendService } from "../../services/backend.service";
import "./admin.scss";
import { useGenericSection } from "../../services/genericSection.service";
import { Row, Col } from "antd";
import { CardModule } from "../components/cardModule/cardModule";

export const Admin = () => {
  const [profile, setProfileInfo] = useState({});

  async function getProfileInfo() {
    backendService.userProfile().then((data) => {
      setProfileInfo(data);
    });
  }

  useEffect(() => {
    backendService.userSettings().then((result) => {
      getProfileInfo();
    });
  }, []);

  return (
    <section className="dashboard-admin-container">
      {
        {
          "Recursos Humanos": <MenuAdministrador />,
        }[profile.aspNetRoleName]
      }
    </section>
  );
};

const MenuAdministrador = () => {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    getGenericItemSections();
  }, []);

  const getGenericItemSections = () => {
    useGenericSection.getGenericItemSectionsByCompany().then((sections) => {
      setSections(sections);
    });
  };

  return (
    <Row gutter={16}>
      {/* <Col xl={6} lg={12} md={12} sm={24} xs={24}>
        <CardModule
          title={"Calendario"}
          description={"Gestión de eventos del calendario"}
          link={"/admin/admin-diary"}
          icon={"icon-calendar"}
        />
      </Col> */}
      <Col xl={8} lg={8} md={12} sm={24} xs={24}>
        <CardModule
          title={"Cartelera"}
          description={"Gestión de publicaciones en cartelera"}
          link={"/admin/course"}
          icon={"icon-billboard"}
        />
      </Col>
      <Col xl={8} lg={8} md={12} sm={24} xs={24}>
        <CardModule
          title={"Recompensas"}
          description={"Gestión de recompensas"}
          link={"/admin/product-filter"}
          icon={"icon-gif"}
        />
      </Col>
      <Col xl={8} lg={8} md={12} sm={24} xs={24}>
        <CardModule
          title={"Tu opinión"}
          description={"Gestión de encuestas"}
          link={"/admin/AdminSurvey"}
          icon={"icon-opinion"}
        />
      </Col>
      <Col xl={12} lg={12} md={24} sm={24} xs={24}>
        <CardModule
          title={"Control"}
          description={
            "Gestión de grupos, eventos, sucursales, categorías, avisos y personalización"
          }
          link={"/admin/control"}
          icon={"icon-docs-published"}
          color={"fill purple"}
        />
      </Col>
      <Col xl={12} lg={12} md={24} sm={24} xs={24}>
        <CardModule
          title={"El Multiverso"}
          description={"Gestión de artículos y galerías"}
          link={"/admin/company-post"}
          icon={"icon-pride-world"}
          color={"fill darkblue"}
        />
      </Col>
      <Col xl={8} lg={8} md={12} sm={24} xs={24}>
        <CardModule
          title={"Videos"}
          description={"Gestión de publicaciones"}
          link={"/admin/admin-audience"}
          icon={"icon-pride-talks"}
        />
      </Col>
      <Col xl={8} lg={8} md={12} sm={24} xs={24}>
        <CardModule
          title={"Usuarios"}
          description={"Gestión de usuarios registrados"}
          link={"/admin/users"}
          icon={"icon-user"}
        />
      </Col>
      <Col xl={8} lg={8} md={24} sm={24} xs={24}>
        <CardModule
          title={"Publicaciones Genéricas"}
          description={
            <ul>
              {sections.map((s, index) => (
                <li key={index}>{s.sectionName}</li>
              ))}
            </ul>
          }
          link={"/admin/generic-section"}
          icon={"icon-docs-published"}
        />
      </Col>
    </Row>
  );
};
