import React from "react";
import { ImgCache } from "../../components/CustonHooks/imgCache";
import CompanyLogo from "../../assets/imgs/Logo.png";
import "./register.scss";
import { Link } from "react-router-dom";

export const RegisterHeader = ({ title, subtitle = "" }) => {
  return (
    <header className={"register__header"}>
      <Link to={"/"}>
        <ImgCache src={CompanyLogo} alt={"PrideMx Logo oficial"} />
      </Link>
      <h2>
        {title}
        <br />
        <span>{subtitle}</span>
      </h2>
    </header>
  );
};
