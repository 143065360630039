import React from "react";
import { Badge } from "reactstrap";
import { Link } from "react-router-dom";
import "./boxStories.scss";

export function BoxStories(props) {
  return (
    <div className="itemcard_post_muro orgullo">
      <h3 className="ttls_in_muro mb-1">
        <Link to="/stories-pride">
        <span>Nuestra Aventura</span>
        </Link>
      </h3>
      <div className="tag">
        <Badge className="badge t_g">Publicación reciente</Badge>
      </div>

      <div className="itemcard_content">
        <div className="itemcard_content_picture">
          <img
            src={props.urlImg}
            alt={props.tituloPost}
            title={props.tituloPost}
          />
        </div>
        <div className="itemcard_content_info">
          <h6 className="itemcard_content_title">
            <Link to={props.linkURL}>
              {props.tituloPost
                ? props.tituloPost.length <= 60
                  ? props.tituloPost
                  : props.tituloPost.substring(0, 60) + "..."
                : ""}
            </Link>
          </h6>
          <p className="itemitemcard_content_description">
            {props.description
              ? props.description.length <= 60
                ? props.description
                : props.description.substring(0, 60) + "..."
              : ""}
          </p>
        </div>
      </div>
    </div>
  );
}
