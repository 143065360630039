import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./blogCompany.scss";
import { IoArrowBackOutline } from "react-icons/io5";
import { Button, Col, Row } from "reactstrap";
import "lightbox.js-react/dist/index.css";

import { SlideshowLightbox } from "lightbox.js-react";
import { companyPostService } from "../../services/companyPost.service";
import Moment from "react-moment";
import { AsideBlogCompanyGallery } from "../companyPost/asideBlogCompanyGallery";
import { FooterHome } from "../../components/footer/footerHome";
import dayjs from "dayjs";

export function BlogGallery(props) {
  const [gallery, setGallery] = useState([]);
  const [selectedGallery, setSelectedGallery] = useState(null);
  let [currentFilter, setCurrentFilter] = useState('');
  const [lastFiter, setLastFiter] = useState({});
  let [images, setImages] = useState(null);
  const postGalleryId = 1;
  const postTypeGallery = 1;
  const [activeAsideSurvey, setStateAsideSurvey] = useState("");

  const getGallery = async () => {
      companyPostService
        .getAllFront(postGalleryId, currentFilter)
        .then((result) => {
          if (result) {
            if (result && result.length > 0) {
              setGallery([]);
              setGallery(result);
            }
          }
        });
  }

  async function getCurrentImagesGallery(item) {
    companyPostService
      .getCompanyPostImagesPathByIdFront(item.companyPostId)
      .then((result) => {
        setImages(null);
        let imageslist = [];
        result.files.map((item) =>
          imageslist.push({
            src: item.path,
            thumbnail: item.path,
          })
        );
        setImages(imageslist);
      });
  }

  const setCurrentGallerySelect = (item) => {
    setSelectedGallery(item);
    getCurrentImagesGallery(item);
  };

  useEffect(() => {
    if (lastFiter.filterMonthDate !== undefined && postGalleryId === lastFiter.postType) {
      const dateLastFilter = dayjs(lastFiter.filterMonthDate).format('YYYY-MM-DD');
      if (currentFilter === dateLastFilter) getGallery();
      else setCurrentFilter(dayjs(lastFiter.filterMonthDate).format('YYYY-MM-DDTHH:mm:ss'));
    }
  }, [lastFiter]);

  useEffect(() => {
    setCurrentFilter(dayjs().format('YYYY-MM-DDTHH:mm:ss'));
  }, []);

  useEffect(() => {
    getGallery();
  }, [currentFilter]);

  useEffect(() => {
    gallery.slice(0, 1).map((item) => setSelectedGallery(item));
    gallery.slice(0, 1).map((item) => getCurrentImagesGallery(item));
  }, [gallery])

  return (
    <article className="dashboard-container-page">
      <Row>
        <Col sm={12}>
          <h2> <Link to="/blog-company"><IoArrowBackOutline></IoArrowBackOutline></Link> El Multiverso | Galería</h2>
        </Col>
        <Col lg={9} md={8} sm={12}>
          <Row>
            <Col sm={12}>
              <div className="blog-gallery-main">
                {selectedGallery ? (
                  <>
                    <img src={selectedGallery.iconPath} alt="" />
                    <div className="content">
                      <h4>{selectedGallery.title}</h4>
                      <div className="flex-end">
                        <span>
                          <Moment
                            date={selectedGallery.publishDate}
                            format="dddd D [de] MMMM [del] YYYY"
                          />
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col sm={12}>
              {images ? (
                <SlideshowLightbox className="blog-gallery-lightbox">
                  {images.map((img, i) => {
                    return (
                      <img
                        key={i}
                        className="blog-gallery-lightbox-item"
                        src={img.src}
                        alt=""
                      />
                    );
                  })}
                </SlideshowLightbox>
              ) : (
                ""
              )}
              <div className="blog-gallery-content">
                <h3>Publicaciones de este mes</h3>
                <div className="cards-container">
                  {gallery.map((card, i) => {
                    return (
                      <div className="card-gallery" key={card.companyPostId}>
                        <img src={card.iconPath} alt="" />
                        <div className="info">
                          <h4>{card.title}</h4>
                          <div className="flex-end">
                            <Button
                              onClick={() => setCurrentGallerySelect(card)}
                              className="btn-outline-white"
                            >
                              Ver Galería <span></span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col lg={3} md={4} sm={12}>
          <AsideBlogCompanyGallery
            getGallery={setCurrentFilter}
            activeAsideSurvey={activeAsideSurvey}
            setLastFiter={setLastFiter}
            postType={postTypeGallery}
            postGalleryId={postGalleryId}
            currentFilter={currentFilter}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <FooterHome />
        </Col>
      </Row>
    </article>
  );
}