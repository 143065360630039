import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  LayoutBackoffice,
  LayoutFull,
  LayoutLess,
  LayoutOperator,
} from "./components/layouts";

import * as Pages from "./pages";
import * as Admin from "./backoffice";
import * as Control from "./control";
import { Operator } from "./control";

export function App() {
  //const [loadApp, setLoadApp] = useState(false);

  // useEffect(() => {
  //   //Google Analytics()
  //   ReactGA.initialize(process.env.REACT_APP_TRACKING_ID_GA);
  //   ReactGA.plugin.require("displayfeatures");
  //   ReactGA.pageview(location.pathname);
  //   setLoadApp(true);
  // }, []);

  // useEffect(() => {
  //   ReactGA.pageview(location.pathname);
  // }, [location.pathname]);

  return (
    <Switch>
      <Route
        exact
        path="/login"
        render={(x) => <LayoutLess children={<Pages.Login {...x} />} />}
      />
      <Route
        exact
        path="/register/:referralToken?"
        render={(x) => <LayoutLess children={<Pages.Register {...x} />} />}
      />
      <Route
        exact
        path="/successfull-register"
        render={(x) => <LayoutLess children={<Pages.SuccessfulReg {...x} />} />}
      />
      <Route
        exact
        path="/verify-account"
        render={(x) => <LayoutLess children={<Pages.VerifyAccount {...x} />} />}
      />
      <Route
        exact
        path="/membership"
        render={(x) => <LayoutLess children={<Pages.Membership {...x} />} />}
      />
      <Route
        exact
        path="/paid-membership"
        render={(x) => (
          <LayoutLess children={<Pages.MembershipPayment {...x} />} />
        )}
      />
      <Route
        exact
        path="/error-membership"
        render={(x) => (
          <LayoutLess children={<Pages.MembershipPayment {...x} />} />
        )}
      />
      <Route
        exact
        path="/reset-password"
        render={(x) => <LayoutLess children={<Pages.ResetPassword {...x} />} />}
      />
      <Route
        exact
        path="/home"
        render={(x) => <LayoutFull children={<Pages.Home />} />}
      />
      <Route
        exact
        path="/surveys"
        render={(x) => <LayoutFull children={<Pages.Surveys />} />}
      />
      <Route
        exact
        path="/board"
        render={(x) => <LayoutFull children={<Pages.Board />} />}
      />
      {/* <Route
        exact
        path="/messages"
        render={(x) => <LayoutFull children={<Pages.Messages />} />}
      /> */}
      {/* <Route
        exact
        path="/messages/:id"
        render={(x) => <LayoutFull children={<Pages.Messages />} />}
      /> */}
      <Route
        exact
        path="/discount"
        render={(x) => <LayoutFull children={<Pages.Discount />} />}
      />{" "}
      <Route
        exact
        path="/directory"
        render={(x) => <LayoutFull children={<Pages.Directory />} />}
      />
      <Route
        exact
        path="/ambassador"
        render={(x) => <LayoutFull children={<Pages.Ambassador />} />}
      />
      <Route
        exact
        path="/pride-music"
        render={(x) => <LayoutFull children={<Pages.PrideMusic />} />}
      />
      <Route
        exact
        path="/interest-group"
        render={(x) => <LayoutFull children={<Pages.InterestGroup />} />}
      />
      <Route
        exact
        path="/details-directory/:DirectoryId"
        render={(x) => <LayoutFull children={<Pages.DetailsDirectory />} />}
      />
      <Route
        exact
        path="/register-services"
        render={(x) => <LayoutFull children={<Pages.RegisterServices />} />}
      />
      <Route
        exact
        path="/register-services/:id"
        render={(x) => <LayoutFull children={<Pages.RegisterServices />} />}
      />
      <Route
        exact
        path="/register-services/card-tokenization-result/:isSuccess"
        render={(x) => (
          <LayoutFull children={<Pages.CardTokenizationResult />} />
        )}
      />
      <Route
        exact
        path="/provider/:id"
        render={(x) => <LayoutFull children={<Pages.Provider {...x} />} />}
      />
      <Route
        exact
        path="/pending-payment"
        render={(x) => <LayoutFull children={<Pages.CashPayment />} />}
      />
      <Route
        exact
        path="/shopping-history"
        render={(x) => <LayoutFull children={<Pages.ShoppingHistory />} />}
      />
      <Route
        exact
        path="/rewards"
        render={(x) => <LayoutFull children={<Pages.Rewards />} />}
      />
      <Route
        exact
        path="/redemption-history"
        render={(x) => <LayoutFull children={<Pages.RedemptionHistory />} />}
      />
      <Route
        exact
        path="/account-status"
        render={(x) => <LayoutFull children={<Pages.AccountStatus />} />}
      />
      <Route
        exact
        path="/profile"
        render={(x) => <LayoutFull children={<Pages.Profile />} />}
      />
      <Route
        exact
        path="/blog-company"
        render={(x) => (
          <LayoutFull
            children={
              <Pages.BlogCompanyPrimary titleComponent="El Multiverso" {...x} />
            }
          />
        )}
      />
      <Route
        exact
        path="/our-company/:id"
        render={(x) => (
          <LayoutFull
            children={
              <Pages.CompanyPostPrimary titleComponent="El Multiverso" {...x} />
            }
          />
        )}
      />
      <Route
        exact
        path="/our-company/:id/report/:reportId"
        render={(x, y, z) => (
          <LayoutFull
            children={
              <Pages.CompanyPostPrimary
                titleComponent="El Multiverso"
                {...x}
                {...y}
              />
            }
          />
        )}
      />
      <Route
        exact
        path="/our-company/:id/:postId"
        render={(x, y) => (
          <LayoutFull
            children={
              <Pages.CompanyPostPrimary
                titleComponent="El Multiverso"
                {...x}
                {...y}
              />
            }
          />
        )}
      />
      <Route
        exact
        path="/blog-company/gallery/:id?"
        render={(x) => (
          <LayoutFull
            children={
              <Pages.BlogGallery titleComponent="Galería Pride" {...x} />
            }
          />
        )}
      />
      <Route
        exact
        path="/stories-pride"
        render={(x) => (
          <LayoutFull
            children={
              <Pages.BlogCompanySecondary
                titleComponent="Nuestra Aventura"
                {...x}
              />
            }
          />
        )}
      />
      <Route
        exact
        path="/stories-pride/:id"
        render={(x) => (
          <LayoutFull
            children={
              <Pages.CompanyPostSecondary
                titleComponent="Nuestra Aventura"
                {...x}
              />
            }
          />
        )}
      />
      <Route
        exact
        path="/stories-pride/:id/report/:reportId"
        render={(x, y) => (
          <LayoutFull
            children={
              <Pages.CompanyPostSecondary
                titleComponent="Nuestra Aventura"
                {...x}
                {...y}
              />
            }
          />
        )}
      />
      <Route
        exact
        path="/stories-pride/:id/:postId"
        render={(x, y) => (
          <LayoutFull
            children={
              <Pages.CompanyPostSecondary
                titleComponent="Nuestra Aventura"
                {...x}
                {...y}
              />
            }
          />
        )}
      />
      <Route
        exact
        path="/help"
        render={(x) => <LayoutFull children={<Pages.Help />} />}
      />
      <Route
        exact
        path="/audience/:idAudience?"
        render={(x) => <LayoutFull children={<Pages.Audience />} />}
      />
      <Route
        exact
        path="/ecommerce"
        render={(x) => <LayoutFull children={<Pages.Ecommerce />} />}
      />
      <Route
        exact
        path="/caring-community"
        render={(x) => <LayoutFull children={<Pages.CaringCommunity />} />}
      />
      <Route
        exact
        path="/caring-community/:id"
        render={(x) => <LayoutFull children={<Pages.Provider {...x} />} />}
      />
      <Route
        exact
        path="/job-bank"
        render={(x) => <LayoutFull children={<Pages.JobBank />} />}
      />
      <Route
        exact
        path="/applicant-sent"
        render={(x) => <LayoutFull children={<Pages.ApplicantSent />} />}
      />
      <Route
        exact
        path="/list-new-jobs"
        render={(x) => <LayoutFull children={<Pages.ListNewJobs />} />}
      />
      <Route
        exact
        path="/details-job"
        render={(x) => <LayoutFull children={<Pages.DetailsJobs />} />}
      />
      <Route
        exact
        path="/details-job/:id"
        render={(x) => <LayoutFull children={<Pages.DetailsJobs />} />}
      />
      <Route
        exact
        path="/register-job"
        render={(x) => <LayoutFull children={<Pages.RegisterJobs />} />}
      />
      <Route
        exact
        path="/edit-job/:id"
        render={(x) => <LayoutFull children={<Pages.EditJob />} />}
      />
      <Route
        exact
        path="/jobs-published"
        render={(x) => <LayoutFull children={<Pages.JobsPublished />} />}
      />
      <Route
        exact
        path="/candidates/:id"
        render={(x) => <LayoutFull children={<Pages.CandidatesNominated />} />}
      />
      <Route
        exact
        path="/order-details/:id"
        render={(x) => <LayoutFull children={<Pages.OrderDetail {...x} />} />}
      />
      <Route
        exact
        path="/canceled"
        render={(x) => (
          <LayoutFull children={<Pages.PaymentCanceled {...x} />} />
        )}
      />
      {/* == BACKOFFICE == */}
      <Route
        exact
        path="/admin"
        render={(x) => <LayoutBackoffice children={<Admin.Admin />} />}
      />
      <Route
        exact
        path="/admin/users"
        render={(x) => <LayoutBackoffice children={<Admin.Users />} />}
      />
      <Route
        exact
        path="/admin/users-details/:id"
        render={(x) => (
          <LayoutBackoffice children={<Admin.UserDetails {...x} />} />
        )}
      />
      <Route
        exact
        path="/admin/admin-audience"
        render={(x) => <LayoutBackoffice children={<Admin.AdminAudience />} />}
      />
      <Route
        exact
        path="/admin/generic-section"
        render={(x) => (
          <LayoutBackoffice children={<Admin.AdminGenericSection />} />
        )}
      />
      <Route
        exact
        path="/admin/bulk-load"
        render={(x) => <LayoutBackoffice children={<Admin.BulkLoad />} />}
      />
      <Route
        exact
        path="/admin/AdminSurvey"
        render={(x) => <LayoutBackoffice children={<Admin.AdminSurvey />} />}
      />
      <Route
        exact
        path="/admin/AdminSurveyScale/:surveyId"
        render={(x) => (
          <LayoutBackoffice children={<Admin.AdminSurveyScale />} />
        )}
      />
      <Route
        exact
        path="/admin/AdminAnswerTypes"
        render={(x) => (
          <LayoutBackoffice children={<Admin.AdminAnswerTypes />} />
        )}
      />
      <Route
        exact
        path="/admin/details-survey-scale/:surveyId/:id"
        render={(x) => (
          <LayoutBackoffice children={<Admin.DetailsSurveyScale {...x} />} />
        )}
      />
      <Route
        exact
        path="/admin/details-survey/:id"
        render={(x) => (
          <LayoutBackoffice children={<Admin.DetailsSurvey {...x} />} />
        )}
      />
      <Route
        exact
        path="/admin/survey-results/:id"
        render={(x) => (
          <LayoutBackoffice children={<Admin.SurveyResults {...x} />} />
        )}
      />
      <Route
        exact
        path="/admin/AdminBoard"
        render={(x) => <LayoutBackoffice children={<Admin.AdminBoard />} />}
      />
      <Route
        exact
        path="/admin/details-category"
        render={(x) => (
          <LayoutBackoffice children={<Admin.DetailsCategory />} />
        )}
      />
      <Route
        exact
        path="/admin/details-category/:id"
        render={(x) => (
          <LayoutBackoffice children={<Admin.DetailsCategory />} />
        )}
      />
      <Route
        exact
        path="/admin/control"
        render={(x) => <LayoutBackoffice children={<Admin.Control />} />}
      />
      <Route
        exact
        path="/admin/company-event-type"
        render={(x) => (
          <LayoutFull children={<Pages.AdminCompanyEventType />} />
        )}
      />
      <Route
        exact
        path="/admin/company-post"
        render={(x) => <LayoutBackoffice children={<Admin.CompanyPost />} />}
      />
      <Route
        exact
        path="/admin/AdminGallery"
        render={(x) => <LayoutBackoffice children={<Pages.AdminGallery />} />}
      />
      <Route
        exact
        path="/admin/CompanyGallery"
        render={(x) => <LayoutBackoffice children={<Pages.CompanyGallery />} />}
      />
      <Route
        exact
        path="/admin/Notice"
        render={(x) => <LayoutBackoffice children={<Admin.Notice />} />}
      />
      {/* <Route
        exact
        path="/admin/admin-diary"
        render={(x) => <LayoutBackoffice children={<Admin.AdminDiary />} />}
      /> */}
      {/* <Route
        exact
        path="/diary"
        render={(x) => <LayoutFull children={<Pages.Diary />} />}
      /> */}
      <Route
        exact
        path="/admin/product-filter"
        render={(x) => (
          <LayoutBackoffice children={<Admin.AdminProductFilter {...x} />} />
        )}
      />
      <Route
        exact
        path="/admin/detail-product-filter/:id"
        render={(x) => (
          <LayoutBackoffice children={<Admin.DetailsProductFilter {...x} />} />
        )}
      />
      {/* == OPERACIONES == */}
      <Route
        exact
        path="/operator"
        render={(x) => <LayoutOperator children={<Operator {...x} />} />}
      />
      <Route
        exact
        path="/operator/homeOps/:tap"
        render={(x) => (
          <LayoutOperator children={<Control.ControlReward {...x} />} />
        )}
      />
      <Route
        exact
        path="/operator/controlreward/:tap"
        render={(x) => (
          <LayoutOperator children={<Control.AdminReward {...x} />} />
        )}
      />
      <Route
        exact
        path="/operator/providers/:tap"
        render={(x) => (
          <LayoutOperator children={<Control.AdminRewardProvider {...x} />} />
        )}
      />
      <Route
        exact
        path="/operator/categories/:tap"
        render={(x) => (
          <LayoutOperator children={<Control.AdminRewardCategory {...x} />} />
        )}
      />
      <Route
        exact
        path="/operator/details-reward/:id"
        render={(x) => (
          <LayoutOperator children={<Control.DetailsReward {...x} />} />
        )}
      />
      <Route
        exact
        path="/operator/load-coupons/:id"
        render={(x) => (
          <LayoutOperator children={<Control.BulkLoadReward {...x} />} />
        )}
      />
      <Route
        exact
        path="/operator/details-rewardCategory/:id"
        render={(x) => (
          <LayoutOperator children={<Control.DetailsRewardCategory {...x} />} />
        )}
      />
      <Route
        exact
        path="/operator/details-rewardProvider/:id"
        render={(x) => (
          <LayoutOperator children={<Control.DetailsRewardProvider {...x} />} />
        )}
      />
      <Route
        exact
        path="/operator/AdminCompany"
        render={(x) => (
          <LayoutOperator children={<Control.AdminCompany {...x} />} />
        )}
      />
      <Route
        exact
        path="/operator/details-company/:id"
        render={(x) => (
          <LayoutOperator children={<Control.DetailsCompany {...x} />} />
        )}
      />
      <Route
        exact
        path="/admin/course"
        render={(x) => <LayoutBackoffice children={<Admin.AdminCourse />} />}
      />
        <Route
            exact
            path="/course-view"
            render={(x) => <LayoutFull children={<Pages.CourseView />} />}
        />
      <Route
        exact
        path="/course-view/course/:courseId"
        render={(x) => <LayoutFull children={<Pages.CourseView />} />}
      />
      <Route
        exact
        path="/course-view/new-event"
        render={(x) => <LayoutFull children={<Pages.EventForm />} />}
      />
      <Route
        exact
        path="/course-view/approve-event"
        render={(x) => <LayoutFull children={<Pages.EventApprove />} />}
      />
      <Route
        exact
        path="/course-view/my-events"
        render={(x) => <LayoutFull children={<Pages.EventApprove />} />}
      />
      <Redirect from="/" to="/home" />
    </Switch>
  );
}
