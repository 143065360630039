/**
 * checks if Push notification and service workers are supported by your browser
 */
function isPushNotificationSupported() {
    return "serviceWorker" in navigator && "PushManager" in window;
}

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
function initializePushNotifications() {
    // request user grant to show notification
    return Notification.requestPermission(function (result) {
        return result;
    });
}
/**
 * shows a notification
 */
async function sendNotification(items) {
    const options = {
        body: "",
        icon: "./imgs/conteninosNotification.png",
        vibrate: [300, 100, 400],
        tag: "ConteniñosNotif",
        requireInteraction: true
    };

    navigator.serviceWorker.ready.then(function (serviceWorker) {
        items.map((item) => {
            options.body = item.message;
            options.tag += `${item.userProfileEventTypeNotificationId}${item.targetId}`;
            serviceWorker.showNotification(item.companyEventTypeNotificationMessage, options);
        });
    });
}

window.self.addEventListener('notificationclick', (event) => {
    console.log('On notification click: ', event.notification.tag);
    event.notification.close();

    // This looks to see if the current is already open and
    // focuses if it is
    event.waitUntil(window.clients.matchAll({
        type: "window"
    }).then((clientList) => {
        for (const client of clientList) {
            if (client.url === '/' && 'focus' in client)
                return client.focus();
        }
        if (window.clients.openWindow)
            return window.clients.openWindow('/');
    }));
});

export {
    isPushNotificationSupported,
    initializePushNotifications,
    sendNotification
};