import React, { useRef, useState } from "react";
import { SelectUsers } from "../../components/Helpers/selectUsers";
import { AudienceUsers } from "../../components/Helpers/audienceUsers";
import { courseService } from "../../services/course.service";
import ImgInfog from "../../assets/imgs/imgInfografia.jpg";
import videoYT from "../../assets/imgs/video-yt.jpg";
import "../admin/admin.scss";
import {
  FaChevronRight,
  FaInfoCircle,
  FaSave,
  FaTimesCircle,
} from "react-icons/fa";
import { companyEventTypeService } from "../../services/companyEventType.service";
import { useEffect } from "react";
import { EventTypes } from "../../enums/eventTypes";
import { useHistory } from "react-router-dom";
import { HeadingAdmin } from "../components/headingAdmin/headingAdmin";
import { TitleAdmin } from "../components/titleAdmin/titleAdmin";
import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Tag,
  Modal,
  Button,
} from "antd";
import { Checkbox } from "../components/checkbox/checkbox";
import { InputAdd } from "../components/inputAdd/inputAdd";
import { InputFile } from "../components/inputFile/inputFile";
import dayjs from "dayjs";
import "./adminCourse.scss";
import { base64ToBlob } from "../../utils/ConvertBase64ToBlob";
import SearchCourse from "../../components/courseAdmin/searchCourse";
import Swal from "sweetalert2";
import { PlusCircleOutlined } from "@ant-design/icons";

export const AdminCourse = () => {
  const history = useHistory();
  const gridRef = useRef();
  const [showEdit, setShowEdit] = useState(false);
  const [userSelec, setUserSelec] = useState([]);
  const [files, setFiles] = useState([]);
  const [createCalendarEvent, setCreateCalendarEvent] = useState(false);
  const [isAudienceAddressed, setIsAudienceAddressed] = useState(false);
  const [iframeData, setIframeData] = useState(null);
  const [fileUpdated, setFileUpdated] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initTraining, setInitTraining] = useState({
    CourseId: 0,
    CourseMediaTypeId: 2,
    Name: "",
    Description: "",
    StartDate: "",
    EndDate: "",
    Reward: 0,
    UrlMedia: "",
    EventId: 0,
    DepartmentId: 0,
    JobRoleId: 0,
  });
  const [form] = Form.useForm();

  const onSave = () => {
    const model = {
      CourseId: initTraining.CourseId,
      CourseMediaTypeId: parseInt(initTraining.CourseMediaTypeId),
      Name: initTraining.Name,
      Description: initTraining.Description,
      StartDate: initTraining.StartDate,
      EndDate: initTraining.EndDate,
      Reward: parseInt(initTraining.Reward),
      UrlMedia: initTraining.UrlMedia,
      EventId: initTraining.EventId,
      DepartmentId: parseInt(initTraining.DepartmentId),
      RoleId: parseInt(initTraining.JobRoleId),
      ListUser: userSelec,
    };
    const isNew = parseInt(initTraining.CourseId) === 0;

    if (
      (model.CourseMediaTypeId === 1 && files.length === 0) ||
      (model.CourseMediaTypeId === 2 && files.length === 0)
    )
      return Swal.fire({
        text: "Debe agregarse al menos un archivo",
        icon: "error",
      });

    if (isAudienceAddressed && userSelec.length <= 0)
      return Swal.fire({
        text: "Debes elegir al menos un usuario",
        icon: "error",
      });

    if (isNew) {
      courseService
        .createCourse(
          model,
          model.CourseMediaTypeId === 3 ? null : files,
          createCalendarEvent
        )
        .then((result) => {
          setShowEdit(false);
          gridRef.current.refreshDataGrid();
          setFiles([]);
        });
    } else if (!isNew) {
      let file = [];
      const fileType = files[0]?.fileName?.split(".")[1] ?? "";
      if (model.CourseMediaTypeId !== 3) {
        if (fileType === "pdf" && model.CourseMediaTypeId === 1)
          file = files[0].name
            ? files
            : [
                base64ToBlob(
                  files[0].file,
                  "application/pdf",
                  files[0].fileName
                ),
              ];

        if (
          fileType !== "pdf" &&
          (model.CourseMediaTypeId === 1 || model.CourseMediaTypeId === 2)
        )
          file = files[0].name
            ? files
            : [
                base64ToBlob(
                  files[0].file,
                  `image/${fileType}`,
                  files[0].fileName
                ),
              ];
      }

      courseService
        .updateCourse(model, model.CourseMediaTypeId === 3 ? null : file)
        .then((result) => {
          setShowEdit(false);
          gridRef.current.refreshDataGrid();
          setFiles([]);
          Swal.fire({ text: "Publicación actualizada", icon: "success" });
        });
    }
  };

  const handleOnNewCourse = () => {
    if (showEdit) {
      clearObjInitTraining();
      form.resetFields();
      setFiles([]);
      setIframeData(null);
      setShowEdit(false);
      return;
    }
    setShowEdit(true);
  };

  const clearObjInitTraining = () =>
    setInitTraining({
      CourseId: 0,
      CourseMediaTypeId: 2,
      Name: "",
      Description: "",
      StartDate: "",
      EndDate: "",
      Reward: 0,
      UrlMedia: "",
      EventId: 0,
      DepartmentId: 0,
      JobRoleId: 0,
    });

  const handleRowSelect = async (gridItem) => {
    setShowEdit(true);
    setInitTraining({
      CourseId: gridItem.courseId,
      CourseMediaTypeId: gridItem.courseMediaTypeId,
      Name: gridItem.name,
      Description: gridItem.description == null ? "" : gridItem.description,
      StartDate: dayjs(gridItem.startDate),
      EndDate: dayjs(gridItem.endDate),
      Reward: gridItem.reward,
      UrlMedia: gridItem.urlMedia,
      EventId: gridItem.eventId,
    });

    if (parseInt(gridItem.courseMediaTypeId) === 3) {
      const range = document.createRange();
      const fragment = range.createContextualFragment(gridItem.urlMedia);
      setIframeData(fragment.firstChild.src);
    }

    if (gridItem.courseId) getAttachments(gridItem.courseId);
  };

  const getAttachments = (courseId) => {
    if (courseId != null && courseId > 0) {
      courseService.getCourseFilesById(courseId).then((result) => {
        if (result != null && result.files.length > 0)
          setFiles([result.files.at(-1)]);
      });
    }
  };

  const handleIframe = (e) => {
    const range = document.createRange();
    const fragment = range.createContextualFragment(e.target.value);
    setIframeData(fragment.firstChild.src);
  };

  const getCompanyEventType = () => {
    companyEventTypeService
      .getByCompanyAndEventType(
        process.env.REACT_APP_COMPANY,
        EventTypes.COURSE
      )
      .then((result) => {
        if (result) setIsAudienceAddressed(result.isAudienceAddressed);
      });
  };

  const handleCheck = (value) => setCreateCalendarEvent(value);

  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const disabledDateEnd = (current) => {
    return current && current < dayjs(initTraining.StartDate);
  };

  const onChangeDate = (date, name) =>
    setInitTraining({ ...initTraining, [name]: dayjs(date) });

  const handleSelectChange = (value) => {
    if (value === 4) setInitTraining({ ...initTraining, CourseMediaTypeId: 0 });
    else setInitTraining({ ...initTraining, CourseMediaTypeId: value });
    setFiles([]);
  };

  const handleCurrentReward = (value) =>
    setInitTraining({ ...initTraining, Reward: value });

  const handleInputValue = (e) =>
    setInitTraining({ ...initTraining, [e.target.name]: e.target.value });

  const onFilesSelected = (files) => {
    setFiles(files);
    setFileUpdated(false);
  };

  const showModal = () => setIsModalOpen(true);

  const handleDeleteImage = () => setFiles([]);

  useEffect(() => {
    getCompanyEventType();
  }, []);

  useEffect(() => {
    if (dayjs(initTraining.StartDate) > dayjs(initTraining.EndDate)) {
      setInitTraining({ ...initTraining, EndDate: "" });
      form.setFieldValue("EndDate", "");
    }
  }, [initTraining.StartDate]);

  return (
    <>
      <section className="dashboard-admin-container">
        <TitleAdmin
          title={"Cartelera"}
          subtitle={"Información de publicaciones"}
          event={() => history.push("/admin")}
        />
        <HeadingAdmin
          text={showEdit ? "Cancelar" : "Agregar cartelera"}
          event={handleOnNewCourse}
          icon={showEdit ? <FaTimesCircle /> : <PlusCircleOutlined />}
        />
        {!showEdit ? (
          <SearchCourse handleRowSelect={handleRowSelect} ref={gridRef} />
        ) : (
          <Form
            className="form-backoffice"
            onFinish={onSave}
            layout={"vertical"}
            form={form}
            fields={[
              {
                name: ["CourseMediaType"],
                value: initTraining.CourseMediaTypeId
                  ? initTraining.CourseMediaTypeId
                  : "",
              },
              {
                name: ["Name"],
                value: initTraining.Name,
              },
              {
                name: ["Description"],
                value: initTraining.Description,
              },
              {
                name: ["StartDate"],
                value:
                  initTraining.StartDate !== "" ? initTraining.StartDate : "",
              },
              {
                name: ["EndDate"],
                value: initTraining.EndDate !== "" ? initTraining.EndDate : "",
              },
              {
                name: ["Reward"],
                value: initTraining.Reward,
              },
              {
                name: ["UrlMedia"],
                value: initTraining.UrlMedia,
              },
              {
                name: ["UrlMedia"],
                value: initTraining.UrlMedia,
              },
            ]}
          >
            <Row gutter={[16, 0]}>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label={"Tipo de taller"}
                  htmlFor={"CourseMediaType"}
                  name={"CourseMediaType"}
                  rules={[
                    {
                      required: true,
                      message: "*Campo Requerido",
                    },
                  ]}
                >
                  <Select
                    name={"CourseMediaType"}
                    placeholder={"Seleccionar tipo de curso"}
                    onChange={handleSelectChange}
                    options={[
                      {
                        value: 2,
                        label: "Link",
                      },
                      {
                        value: 1,
                        label: "Attachment",
                      },
                      {
                        value: 3,
                        label: "Video de Youtube",
                      },
                      {
                        value: 4,
                        label: "Borrar",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label={"Nombre"}
                  htmlFor={"Name"}
                  rules={[
                    {
                      required: true,
                      message: "*Campo requerido",
                    },
                    {
                      pattern: new RegExp(/^\S/),
                      message: "No se aceptan espacios en blanco al inicio",
                    },
                  ]}
                  name={"Name"}
                >
                  <Input name="Name" onChange={handleInputValue} />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label={"Descripción"}
                  htmlFor={"Description"}
                  rules={[
                    {
                      required: true,
                      message: "*Campo requerido",
                    },
                    {
                      pattern: new RegExp(/^\S/),
                      message: "No se aceptan espacios en blanco al inicio",
                    },
                  ]}
                  name={"Description"}
                >
                  <Input name="Description" onChange={handleInputValue} />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label={"Fecha de inicio"}
                  htmlFor={"StartDate"}
                  name={"StartDate"}
                  rules={[
                    {
                      required: true,
                      message: "*Campo Requerido",
                    },
                  ]}
                >
                  <DatePicker
                    name="StartDate"
                    placeholder={"Inicia"}
                    allowEmpty={false}
                    allowClear={false}
                    onChange={(_, date) => onChangeDate(date, "StartDate")}
                    format={"DD/MMM/YYYY"}
                    showToday={false}
                    disabledDate={disabledDate}
                  />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label={"Fecha de finalización"}
                  htmlFor={"EndDate"}
                  name={"EndDate"}
                  rules={[
                    {
                      required: true,
                      message: "*Campo Requerido",
                    },
                  ]}
                >
                  <DatePicker
                    name="EndDate"
                    placeholder={"Termina"}
                    allowEmpty={false}
                    allowClear={false}
                    onChange={(_, date) => onChangeDate(date, "EndDate")}
                    format={"DD/MMM/YYYY"}
                    showToday={false}
                    disabled={initTraining.StartDate === ""}
                    disabledDate={disabledDateEnd}
                  />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item label={"Reward"} htmlFor={"Reward"}>
                  <InputAdd
                    maxLength={6}
                    maxQuantity={99999}
                    minQuantity={-99999}
                    valueInt={initTraining.Reward}
                    handleCurrentNumber={handleCurrentReward}
                  />
                </Form.Item>
              </Col>
              {/* {parseInt(initTraining.CourseId) === 0 && (
                <Col xs={24}>
                  <Form.Item
                    label={"Evento de calendario"}
                    htmlFor={"CreateCalendarEvent"}
                  >
                    <Checkbox
                      text={"Crear evento de calendario"}
                      label={"CreateCalendarEvent"}
                      name={createCalendarEvent}
                      handleValueCheck={handleCheck}
                    />
                  </Form.Item>
                </Col>
              )} */}
              {
                {
                  1: (
                    <>
                      <Col xs={24}>
                        <Form.Item label={"Archivo para adjuntar"}>
                          <InputFile
                            onFilesSelected={onFilesSelected}
                            edit={initTraining.CourseId > 0}
                            valueFile={files}
                            width={1200}
                            height={800}
                            nameFile={"fileAttachment"}
                            accept={["image", "pdf"]}
                            deleteImage={handleDeleteImage}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  ),
                  2: (
                    <>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Url Media"
                          htmlFor={"UrlMedia"}
                          name={"UrlMedia"}
                          rules={[
                            {
                              required: true,
                              message: "*Campo Requerido",
                            },
                          ]}
                          initialValue={initTraining.UrlMedia}
                        >
                          <Input name="UrlMedia" onChange={handleInputValue} />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item label={"Imagen de link"}>
                          <InputFile
                            onFilesSelected={onFilesSelected}
                            width={1000}
                            height={1250}
                            edit={showEdit && initTraining.CourseId > 0}
                            valueFile={files}
                            nameFile={"thumbLink"}
                            accept={["image"]}
                            deleteImage={handleDeleteImage}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  ),
                  3: (
                    <>
                      <Col md={12} sm={24} xs={24}>
                        <Form.Item
                          label={`Iframe para video (Youtube)`}
                          htmlFor={"UrlMedia"}
                          name={"UrlMedia"}
                          rules={[
                            {
                              required: true,
                              message: "*Campo Requerido",
                            },
                          ]}
                          initialValue={initTraining.UrlMedia}
                        >
                          <Input.TextArea
                            rows="3"
                            name="UrlMedia"
                            onChange={(e) => {
                              handleInputValue(e);
                              handleIframe(e);
                            }}
                          ></Input.TextArea>
                        </Form.Item>
                      </Col>
                      <Col md={12} sm={24} xs={24}>
                        {iframeData ? (
                          <iframe
                            src={iframeData}
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          ></iframe>
                        ) : (
                          <div className={"iframe-youtube-info"}>
                            <Tag
                              icon={<FaInfoCircle />}
                              color="#cd201f"
                              onClick={showModal}
                              className={"info-yt"}
                            >
                              <p>
                                ¿Cómo crear un iframe en youtube?{" "}
                                <FaChevronRight />
                              </p>
                            </Tag>
                            <img
                              className="yt"
                              src={videoYT}
                              alt="Video Youtube"
                            />
                          </div>
                        )}
                      </Col>
                    </>
                  ),
                }[initTraining.CourseMediaTypeId]
              }
              {/* <Col md={4} sm={12}>
                                {(inputs.CourseMediaTypeId == 1 || inputs.CourseMediaTypeId == 2)&&
                                    inputs.CourseId != null &&
                                    inputs.CourseId > 0 ? (
                                        <CourseAttachment
                                            CourseId={inputs.CourseId}
                                            IsDelete={true}
                                            setCountStorageFiles={setCountStorageFiles}
                                        />
                                    ) : null}
                            </Col> */}
              <Col xs={24}>
                {isAudienceAddressed &&
                  (initTraining.CourseId > 0 ? (
                    <>
                      <AudienceUsers
                        targetId={initTraining.CourseId}
                        eventType="Curso"
                        selectUser={setUserSelec}
                      />
                    </>
                  ) : (
                    <>
                      <SelectUsers
                        selectUser={setUserSelec}
                        title={"Taller dirigido a:"}
                      />
                    </>
                  ))}
              </Col>
              <Col xs={24}>
                <div className="form-backoffice-footer">
                  <Button type={"primary"} htmlType="submit">
                    <p>Guardar</p>
                    <FaSave />
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </section>
      <Modal
        open={isModalOpen}
        footer={(_, { OkBtn, CancelBtn }) => <></>}
        onCancel={() => setIsModalOpen(!isModalOpen)}
      >
        <img
          src={ImgInfog}
          width="100%"
          style={{ width: "100%", borderRadius: "1rem", objectFit: "contain" }}
          alt="Infografía Kuimby"
        />
      </Modal>
    </>
  );
};
